import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useBookingsList() {

    // Use toast
    const toast = useToast();

    const refBookingListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'Código' },
        { key: 'day', sortable: true, label: 'Día' },
        { key: 'schedule', sortable: true, label: 'Horario' },
        { key: 'fullname', sortable: true, label: 'Usuario' },
        { key: 'document_number', sortable: true, label: 'DNI' },
        { key: 'email', sortable: true, label: 'Correo' },
        { key: 'client.phone', sortable: true, label: 'Celular' },
        { key: 'doctor_fullname', sortable: true, label: 'Doctor(a)' },
        { key: 'survey_state_name', sortable: true, label: 'ESTADO </br> ENCUESTA' },
        { key: 'createdAt', sortable: true, label: 'Fecha creación' },
        { key: 'actions', sortable: false, label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalBookings = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const rangePickerFilter = ref(null);
    const clientsFilter = ref(null);
    const availableTimesFilter = ref(null);
    const surveyStatesFilter = ref(null);
    const doctorsFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refBookingListTable.value ? refBookingListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBookings.value,
        }
    });

    const refetchData = () => {
        refBookingListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, rangePickerFilter, clientsFilter, availableTimesFilter, surveyStatesFilter, doctorsFilter], () => {
        refetchData();
    });

    const fetchBookings = (ctx, callback) => {
        store
            .dispatch('app-booking/fetchBookings', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                range: rangePickerFilter.value,
                clients: clientsFilter.value,
                availableTimes: availableTimesFilter.value,
                surveystates: surveyStatesFilter.value,
                doctors: doctorsFilter.value
            })
            .then(response => {
                const { bookings, total } = response.data;
                callback(bookings);
                totalBookings.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de reservas',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchBookings,
        tableColumns,
        perPage,
        currentPage,
        totalBookings,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBookingListTable,

        refetchData,

        // Extra Filters
        rangePickerFilter,
        clientsFilter,
        availableTimesFilter,
        surveyStatesFilter,
        doctorsFilter
    }
}