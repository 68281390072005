<template>

	<div>

		<!-- Filters -->
		<bookings-list-filters
            :range-picker-filter.sync="rangePickerFilter"
            :clients-filter.sync="clientsFilter"
            :available-times-filter.sync="availableTimesFilter"
			:survey-states-filter.sync="surveyStatesFilter"
			:doctors-filter.sync="doctorsFilter"
            :clients-options="clientsOptions"
            :available-times-options="availableTimesOptions"
			:survey-states-options="surveyStatesOptions"
			:doctors-options="doctorsOptions"
        />
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar reserva ..."
								trim
							/>
							<b-button
								:to="{ name: 'booking-create' }"
								variant="primary"							
								>
								<span class="text-nowrap">Agregar Reserva</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refBookingListTable"
				class="position-relative"
				:style="totalBookings < 3 && totalBookings > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchBookings"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">

				<template #head(survey_state_name)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(code)="data">
					<b-link
						:to="{ name: 'booking-edit', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap">
                        {{ data.item.code}}
                    </b-link>
				</template>

				<template #cell(day)="data">
                    {{ moment(data.item.day).format('DD/MM/YYYY') }}
				</template>

				<template #cell(schedule)="data">
                    {{ data.item.available_time.start_time }} - {{ data.item.available_time.end_time }}
				</template>

				<template #cell(fullname)="data">
					<span v-if="data.item.client.names">{{ data.item.client.names }} {{ data.item.client.first_surname }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(document_number)="data">
					<span v-if="data.item.client.document_number">{{ data.item.client.document_number }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(email)="data">
					<span v-if="data.item.client.email">{{ data.item.client.email }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(doctor_fullname)="data">
					<span v-if="data.item.doctor_fullname">{{ data.item.doctor_fullname }}</span>
                    <span v-else>-</span>
				</template>

				<template #cell(survey_state_name)="data">
					<b-badge
                        pill
                        :variant="data.item.survey_state_color"
                    >
						{{ data.item.survey_state_name }}
                    </b-badge>
                </template>

				<template #cell(createdAt)="data">
                    {{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item 
							:to="{ name: 'booking-edit', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.survey_state_id !== 3 && data.item.client.email && allowToSendSurvey(data.item.day)"
                            @click="sendSurveyByMail(data.item.id)"
                        >
                            <feather-icon icon="SendIcon" />
                            <span class="align-middle ml-50">Mandar encuesta</span>
                        </b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.survey_state_id !== 3 && !data.item.client.email && data.item.client.phone && data.item.client.phone.length === 9 && allowToSendSurvey(data.item.day)"
                            @click="sendSurveyByWhatsapp(data.item.id, data.item.client, data.item.day, data.item.available_time.start_time, data.item.survey.slug)"
                        >
                            <feather-icon icon="SendIcon" />
                            <span class="align-middle ml-50">Mandar encuesta</span>
                        </b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.survey_state_id === 3"
							@click="openSurveyModal(data.item)"
                        >
                            <feather-icon icon="MessageSquareIcon" />
                            <span class="align-middle ml-50">Ver encuesta</span>
                        </b-dropdown-item>

						<b-dropdown-item @click="deleteBooking(data.item.id)">
							<feather-icon icon="TrashIcon" />
							<span class="align-middle ml-50">Eliminar</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalBookings"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<BSurveyModal
			ref="bsurveymodal"
			:data="surveyData"
		/>
	</div>
</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import BSurveyModal from '@core/components/b-survey-modal/BSurveyModal.vue';
	import BookingsListFilters from './BookingsListFilters.vue';
	import { useToast } from 'vue-toastification/composition';
	import { ref, onUnmounted } from '@vue/composition-api';
	import bookingStoreModule from '../bookingStoreModule';
	import useBookingsList from './useBookingsList';
	import VueSweetalert2 from 'vue-sweetalert2';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			BookingsListFilters,
			BSurveyModal,			
			vSelect
		},
		data() {                              
            return {
                moment,

				clientsOptions: [],
                availableTimesOptions: [],
				surveyStatesOptions: [],
				doctorsOptions: []
            }
        },
		beforeMount (){
            this.getClients();
            this.getAvailableTimes();
			this.getSurveyStates();
			this.getDoctors();
        },
		methods: {
			async getClients (){
                let response = await axios.get('/bookings/clients/select');
                let data = [];
                response.data.map(item => {
					let label = `${item.client.names} ${item.client.first_surname} ${item.client.second_surname}`.toUpperCase() + ` (${item.client.document_number})`;
                    data.push({ label, value: item.client.id });
                });
                this.clientsOptions = data;
            },
            async getAvailableTimes (){
                let response = await axios.get('/available-times/select');
                this.availableTimesOptions = response.data;
            },
			async getSurveyStates (){
                let response = await axios.get('/survey-states/select');
                this.surveyStatesOptions = response.data;
            },
			async getDoctors (){
                let response = await axios.get('/doctors/select');
                this.doctorsOptions = response.data;
            }
		},
		setup() {

			// Use toast
			const toast = useToast();

			// REFS
			const bsurveymodal = ref(null);
			const surveyData = ref({});

			const USER_APP_STORE_MODULE_NAME = 'app-booking';

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, bookingStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
			});

			const allowToSendSurvey = (bookingDay) => {

				const today = moment().format('YYYY-MM-DD')

				return today >= bookingDay

			};
		
			const deleteBooking = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de eliminar la reserva?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {
					if (result.value) {
						store.dispatch('app-booking/deleteBooking', { id })
							.then( (response) => {
								refetchData();
								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});
							})
							.catch( () => {
								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar la reserva',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});
							});
					}
				});

			};
			
			const sendSurveyByMail = (id) => {

				Vue.swal({
					title: '¿Está seguro(a) de enviar la encuesta por correo?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, enviar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {
					if (result.value) {
						store.dispatch('app-booking/sendSurveyByMail', { id })
							.then( (response) => {
								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});
							})
							.catch( () => {
								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al enviar la encuesta por correo',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});
							});
					}
				});

			};

			const sendSurveyByWhatsapp = (id, { phone, names, first_surname }, day, start_time, slug) => {
				
				store.dispatch('app-booking/sendSurveyByWhatsapp', { id })
					.then( (response) => {
						refetchData();
						
						toast({
							component: ToastificationContent,
							props: {
								title: response.data.message,
								icon: 'CheckIcon',
								variant: 'success'
							}
						});

						const url = `${process.env.VUE_APP_ENCUESTAS_URL}/${slug}`;

						if (names) {
							window.open(`https://api.whatsapp.com/send?phone=+51${phone}&text=Hola, ${names} ${first_surname}. Agradeceríamos que pueda realizar la *ENCUESTA DE SATISFACCIÓN*, en base a la cita del *${moment(day).format('DD/MM/YYYY')} ${start_time}*. Su feedback nos permitirá seguir mejorando para usted. ${url}`, '_blank');
						} else {
							window.open(`https://api.whatsapp.com/send?phone=+51${phone}&text=Hola. Agradeceríamos que pueda realizar la *ENCUESTA DE SATISFACCIÓN*, en base a la cita del *${moment(day).format('DD/MM/YYYY')} ${start_time}*. Su feedback nos permitirá seguir mejorando para usted. ${url}`, '_blank');
						}
					})
					.catch( () => {
						toast({
							component: ToastificationContent,
							props: {
								title: 'Error al enviar la encuesta por correo',
								icon: 'AlertTriangleIcon',
								variant: 'danger'
							}
						});
					});

			};

			const openSurveyModal = (data) => {
				const item = {
					completion_date: data.survey.completion_date ? moment(data.survey.completion_date).format('DD/MM/YYYY HH:mm:ss') : '-',
					user: (data.client.names ? `${ data.client.names } ${ data.client.first_surname }` : '-'),
					score: data.survey.score,
					comments: data.survey.comments
				}

				surveyData.value = item;

				bsurveymodal.value.$refs.modal.show();
			};

			const {
				fetchBookings,
				tableColumns,
				perPage,
				currentPage,
				totalBookings,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refBookingListTable,
				refetchData,

				// Extra Filters
                rangePickerFilter,
                clientsFilter,
                availableTimesFilter,
				surveyStatesFilter,
				doctorsFilter
			} = useBookingsList();
			
			return {
				fetchBookings,
				tableColumns,
				perPage,
				currentPage,
				totalBookings,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refBookingListTable,
				refetchData,

				// REFS
				bsurveymodal,
				surveyData,

				// Extra Filters
                rangePickerFilter,
                clientsFilter,
                availableTimesFilter,
				surveyStatesFilter,
				doctorsFilter,
				
				// Filter
				openSurveyModal,
				allowToSendSurvey,
				sendSurveyByMail,
				sendSurveyByWhatsapp,
				deleteBooking
			}
		}
	}

</script>

<style lang="scss" scoped>

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>