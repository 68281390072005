<template>
    <b-modal
        ref="modal"
        title="DETALLE DE LA ENCUESTA"
        centered
        modal-class="modal-primary"
        no-close-on-backdrop
        no-close-on-esc
    >

        <b-form @submit.prevent>
            <b-row>
                
                <!-- COMPLETATION DATE -->
                <b-col cols="12"
                >
                    <b-form-group
                        label="FECHA DE COMPLETADO"
                        label-for="completion_date"
                    >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="CalendarIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                id="completion_date"
                                type="text"
                                v-model="object.completion_date"
                                disabled
                                readonly
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                                    
                <!-- FULLNAME -->
                <b-col cols="12"
                >
                    <b-form-group
                        label="USUARIO"
                        label-for="user"
                    >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="UserIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                id="user"
                                type="text"
                                v-model="object.user"
                                disabled
                                readonly
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
                                    
                <!-- SCORE -->
                <b-col 
                    cols="12"
                    class="mb-2"
                >
                    <label>PUNTAJE</label>
                    <div class="item-meta">
                        <ul class="unstyled-list list-inline mb-25">
                            <li
                                v-for="star in 5"
                                :key="star"
                                class="ratings-list-item"
                            >
                                <feather-icon
                                    icon="StarIcon"
                                    size="18"
                                    class="mr-25"
                                    :class="[{'fill-current': star <= object.score}, star <= object.score ? 'text-warning' : 'text-muted']"
                                />
                            </li>
                        </ul>
                    </div>
                </b-col>
                
                <!-- COMMENTS -->
                <b-col cols="12"
                >
                    <b-form-group
                        label="COMENTARIOS"
                        label-for="comments"
                    >
                            <b-form-textarea
                            id="comments"
                            v-model="object.comments"
                            rows="4"
                            disabled
                            readonly
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>

            </b-row>
        </b-form>
        
        <template #modal-footer>

            <b-button variant="outline-danger" @click="close()">
                CERRAR
            </b-button>

        </template>
        
    </b-modal>
</template>

<script>

    import { ref, watch } from '@vue/composition-api';

    export default {
        props: {
            data: Object, 
        },
        setup(props) {

            // REFS
            const object = ref(props.data);
            const modal = ref(null);

            // WATCH
            watch( () => props.data, (newValue) => {

                object.value = {};
                object.value = newValue;

                modal.value.show();

            });

            const close = () => {
                object.value = {};
                modal.value.hide();
            };

            return {
                // DATA
                object,

                // REFS
                modal,

                // METHODS
                close
            }
        }
    }

</script>