<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>FILTROS</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Cliente(s)</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="clientsFilterArray"
                        :options="clientsOptions"
                        class="w-100"
                        @input="inputClientsVal()"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Horario(s) disponible(s)</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="availableTimesFilterArray"
                        :options="availableTimesOptions"
                        class="w-100"
                        @input="inputAvailableTimesVal()"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="6">
                    <label>Rango de fechas de la reserva</label>
                    <flat-pickr
                        @on-change="doSomethingOnChange"
                        v-model="rangePicker"
                        class="form-control"
                        :config="config"
                        placeholder="YYYY-MM-DD"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Estado(s) de la encuesta</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="surveyStatesFilterArray"
                        :options="surveyStatesOptions"
                        class="w-100"
                        @input="inputSurveyStatesVal()"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="6"
                    class="mb-2"
                >
                    <label>Doctor(es)</label>
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple="multiple"
                        v-model="doctorsFilterArray"
                        :options="doctorsOptions"
                        class="w-100"
                        @input="inputDoctorsVal()"
                    />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>

    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import vSelect from 'vue-select';

    export default {
        components: {
            flatPickr,
            vSelect
        },
        data (){
            return {
                config: {
                    mode: 'range',
                    locale: Spanish
                },
                minDate: null,
                maxDate: null,
                rangePicker: null,
                clientsFilterArray: [],
                availableTimesFilterArray: [],
                surveyStatesFilterArray: [],
                doctorsFilterArray: []
            }
        },
        props: {
            clientsFilter: {
                type: [String, null],
                default: null
            },
            availableTimesFilter: {
                type: [String, null],
                default: null
            },
            clientsOptions: {
                type: Array,
                required: true
            },
            availableTimesOptions: {
                type: Array,
                required: true
            },
            surveyStatesOptions: {
                type: Array,
                required: true
            },
            doctorsOptions: {
                type: Array,
                required: true
            }
        },
        methods: {
            doSomethingOnChange (){
                if (typeof this.rangePicker === 'string')
                {
                    let rangeDates = this.rangePicker.split( 'a ');
                    this.minDate = rangeDates[0];
                    this.maxDate = rangeDates[1];

                    let range = this.minDate+this.maxDate;

                    this.$emit('update:rangePickerFilter', range);
                }
            },
            inputClientsVal (){
                if (this.clientsFilterArray){
                    let array = [];
                    this.clientsFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:clientsFilter', array.join(','));
                    return array;
                }
                return null;
            },
            inputAvailableTimesVal (){
                if (this.availableTimesFilterArray){
                    let array = [];
                    this.availableTimesFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:availableTimesFilter', array.join(','));
                    return array;
                }
                return null;
            },
            inputSurveyStatesVal (){
                if (this.surveyStatesFilterArray){
                    let array = [];
                    this.surveyStatesFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:surveyStatesFilter', array.join(','));
                    return array;
                }
                return null;
            },
            inputDoctorsVal (){
                if (this.doctorsFilterArray){
                    let array = [];
                    this.doctorsFilterArray.map(item => {
                        array.push(item.value);
                    });
                    this.$emit('update:doctorsFilter', array.join(','));
                    return array;
                }
                return null;
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>